import tw, {styled} from 'twin.macro';
import { Link } from 'gatsby';

export const Container = tw.section`w-full block font-sans`;
export const Wrapper = tw.div`flex items-stretch justify-start w-full min-h-screen`
export const Body = styled.div`
    ${tw`p-16 w-full md:p-32 bg-white`}
`;
export const LogoLink = tw(Link)`block text-20 text-orange`;
export const Header = tw.header`w-full flex items-center justify-between bg-grey-dark p-16 h-64 text-orange`;
export const AccountName = tw.span`block cursor-pointer`
export const Nav = tw.nav`min-w-48 md:min-w-64 bg-grey-medium h-screen sticky top-0`
export const NavLink = styled(Link)`
    ${tw`p-12 md:p-16 block w-48 h-48 md:w-64 md:h-64 text-black bg-transparent hover:bg-grey-dark`}
    ${({active}) => active ? tw`bg-grey-dark text-orange` : tw`hover:text-white`}
    svg {
        ${tw`w-full h-full`}
    }
`;
