import React, { useCallback, useMemo } from 'react'
import { Container, Header, LogoLink, Wrapper, Body, Nav, NavLink, AccountName } from './dashboardWrapperStyles'
import Icon from '../icon';
import { DomData } from 'utils/dom';

export const DashboardWrapper = ({path, children}) => {
    const { isBrowser } = DomData();
    const handleLogOut = useCallback(() => {
        if (isBrowser) {
            window.localStorage.removeItem('dashboard-login-token');
            window.localStorage.removeItem('dashboard-login-email');
            window.location.reload();
        }
    }, [isBrowser]);
    return useMemo(() => (
        <Container>
            <Header>
                <LogoLink to={`/dashboard/`}>
                    Paris Cat Bookings
                </LogoLink>
                <AccountName onClick={() => handleLogOut()}>Log Out</AccountName>
            </Header>
            <Wrapper>
                <Nav>
                    <NavLink to="/dashboard/" title={`Home`} active={path === '/dashboard/' ? 'true' : null}>
                        <Icon name={'home'} />
                    </NavLink>
                    <NavLink to="/dashboard/calendar" title={`Calendar`} active={path.includes('calendar') ? 'true' : null}>
                        <Icon name={'calendar'} />
                    </NavLink>
                    <NavLink to="/dashboard/events" title={`Events`} active={path.includes('events') ? 'true' : null}>
                        <Icon name={'speaker'} />
                    </NavLink>
                    <NavLink to="/dashboard/bookings" title={`Bookings`} active={path.includes('bookings') ? 'true' : null}>
                        <Icon name={'list'} />
                    </NavLink>
                    <NavLink to="/dashboard/customers" title={`Customers`} active={path.includes('customers') ? 'true' : null}>
                        <Icon name={'user'} />
                    </NavLink>
                    <NavLink to="/dashboard/vouchers" title={`Gift Vouchers`} active={path.includes('vouchers') ? 'true' : null}>
                        <Icon name={'gift'} />
                    </NavLink>
                    <NavLink to="/dashboard/settings" title={`Settings`} active={path.includes('settings') ? 'true' : null}>
                        <Icon name={'settings'} />
                    </NavLink>
                </Nav>
                <Body>
                    {children}
                </Body>
            </Wrapper>
        </Container>
    ), [path, children]);
};

