import React, { useEffect, useMemo, useState } from 'react'
import { DomData } from 'utils/dom';
import { Main } from './layoutStyles'
import { DashboardWrapper } from 'components/dashboardWrapper/dashboardWrapper'

const Layout = ({ pageContext, children, location }) => {
    const { isBrowser } = DomData();
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    // const {menu, settings, title} = pageContext;
    // const mainMenu = getMainMenu(menu); 
    const requiresAuth = useMemo(() => (
        location?.pathname?.includes('dashboard')
    ), [location]);
    useEffect(() => {
        if (isBrowser && requiresAuth) {
            const token = window.localStorage.getItem('dashboard-login-token');
            async function verifyLogin() { 
                fetch(`${process.env.GATSBY_EXPRESS_ENDPOINT}/admin/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({token})
                })
                .then((res) => res.json())
                .then((data) => {
                    if (data.valid) {
                        setIsLoggedIn(true);
                    } else {
                        window.location.href = '/login';
                    }
                })
                .catch((err) => {
                    console.error(err)
                    window.location.href = '/login';
                })
            };
            verifyLogin();
        }
    }, []);

    if (requiresAuth) {
        return (
            <DashboardWrapper path={location.pathname}>
                {requiresAuth && isLoggedIn ? children : null}
            </DashboardWrapper>
        )
    } else {
        return (
            <>            
                {/* <Header links={mainMenu} /> */}
                <Main style={{backgroundImage: `url(/img/bg.svg)`}}>
                    {children}
                </Main>
                {/* <Footer /> */}
            </>
        )
    }
}

export default Layout
